//
// ------------------------------ RESPONSIVE BREAKPOINTS ------------------------------
//
// responsive classes: "show-over-N" and "show-under-N"
// two can be used together to create an interval, e.g., "show-over-650 show-under-1024"
//
// N in { [480], 650, 760, [840], 1024, 1265 }
// phone < [480]px < tablet < [840]px < desktop
//
//
//    show-over-1265     show-under-1265
//    show-over-1024     show-under-1024
//    show-over-840      show-under-840
//    show-over-760      show-under-760
//    show-over-650      show-under-650
//    show-over-480      show-under-480
// 
// 
//
// ---------------------------
// [1265, inf) desktop
//
@media screen and (min-width: 1265px) {
    // show
    .show-over-1265,
    .show-over-1024,
    .show-over-840,
    .show-over-760,
    .show-over-650,
    .show-over-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-under-1265,
    .show-under-1024,
    .show-under-840,
    .show-under-760,
    .show-under-650,
    .show-under-480 {
        display: none;
    }
}

//
// ---------------------------
// [1024, 1265) desktop
//
@media screen and (min-width: 1024px) and (max-width: 1264px) {
    // show
    .show-under-1265,
    .show-over-1024,
    .show-over-840,
    .show-over-760,
    .show-over-650,
    .show-over-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-over-1265,
    .show-under-1024,
    .show-under-840,
    .show-under-760,
    .show-under-650,
    .show-under-480 {
        display: none;
    }
}

//
// ---------------------------
// [840, 1024) desktop
//
@media screen and (min-width: 840px) and (max-width: 1023px) {
    // show
    .show-under-1265,
    .show-under-1024,
    .show-over-840,
    .show-over-760,
    .show-over-650,
    .show-over-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-over-1265,
    .show-over-1024,
    .show-under-840,
    .show-under-760,
    .show-under-650,
    .show-under-480 {
        display: none;
    }
}

//
// ---------------------------
// [760, 840) tablet
// 
@media screen and (min-width: 760px) and (max-width: 839px) {
    // show
    .show-under-1265,
    .show-under-1024,
    .show-under-840,
    .show-over-760,
    .show-over-650,
    .show-over-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-over-1265,
    .show-over-1024,
    .show-over-840,
    .show-under-760,
    .show-under-650,
    .show-under-480 {
        display: none;
    }
}

// 
// ---------------------------
// [650, 760) tablet
// 
@media screen and (min-width: 650px) and (max-width: 759px) {
    // show
    .show-under-1265,
    .show-under-1024,
    .show-under-840,
    .show-under-760,
    .show-over-650,
    .show-over-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-over-1265,
    .show-over-1024,
    .show-over-840,
    .show-over-760,
    .show-under-650,
    .show-under-480 {
        display: none;
    }
}

//
// ---------------------------
// [480, 650) tablet
//
@media screen and (min-width: 480px) and (max-width:649px) {
    // show
    .show-under-1265,
    .show-under-1024,
    .show-under-840,
    .show-under-760,
    .show-under-650,
    .show-over-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-over-1265,
    .show-over-1024,
    .show-over-840,
    .show-over-760,
    .show-over-650,
    .show-under-480 {
        display: none;
    }
}

// 
// ---------------------------
// [0, 480) phone
//
@media screen and (max-width: 479px) {
    // show
    .show-under-1265,
    .show-under-1024,
    .show-under-840,
    .show-under-760,
    .show-under-650,
    .show-under-480 {
        display: block;
        &.inline {
            display: inline-block;
        }
    }
    // hide
    .show-over-1265,
    .show-over-1024,
    .show-over-840,
    .show-over-760,
    .show-over-650,
    .show-over-480 {
        display: none;
    }
}
