@media screen and (min-width: 1265px) {
  .show-over-1265,
  .show-over-1024,
  .show-over-840,
  .show-over-760,
  .show-over-650,
  .show-over-480 {
    display: block; }
    .show-over-1265.inline,
    .show-over-1024.inline,
    .show-over-840.inline,
    .show-over-760.inline,
    .show-over-650.inline,
    .show-over-480.inline {
      display: inline-block; }
  .show-under-1265,
  .show-under-1024,
  .show-under-840,
  .show-under-760,
  .show-under-650,
  .show-under-480 {
    display: none; } }

@media screen and (min-width: 1024px) and (max-width: 1264px) {
  .show-under-1265,
  .show-over-1024,
  .show-over-840,
  .show-over-760,
  .show-over-650,
  .show-over-480 {
    display: block; }
    .show-under-1265.inline,
    .show-over-1024.inline,
    .show-over-840.inline,
    .show-over-760.inline,
    .show-over-650.inline,
    .show-over-480.inline {
      display: inline-block; }
  .show-over-1265,
  .show-under-1024,
  .show-under-840,
  .show-under-760,
  .show-under-650,
  .show-under-480 {
    display: none; } }

@media screen and (min-width: 840px) and (max-width: 1023px) {
  .show-under-1265,
  .show-under-1024,
  .show-over-840,
  .show-over-760,
  .show-over-650,
  .show-over-480 {
    display: block; }
    .show-under-1265.inline,
    .show-under-1024.inline,
    .show-over-840.inline,
    .show-over-760.inline,
    .show-over-650.inline,
    .show-over-480.inline {
      display: inline-block; }
  .show-over-1265,
  .show-over-1024,
  .show-under-840,
  .show-under-760,
  .show-under-650,
  .show-under-480 {
    display: none; } }

@media screen and (min-width: 760px) and (max-width: 839px) {
  .show-under-1265,
  .show-under-1024,
  .show-under-840,
  .show-over-760,
  .show-over-650,
  .show-over-480 {
    display: block; }
    .show-under-1265.inline,
    .show-under-1024.inline,
    .show-under-840.inline,
    .show-over-760.inline,
    .show-over-650.inline,
    .show-over-480.inline {
      display: inline-block; }
  .show-over-1265,
  .show-over-1024,
  .show-over-840,
  .show-under-760,
  .show-under-650,
  .show-under-480 {
    display: none; } }

@media screen and (min-width: 650px) and (max-width: 759px) {
  .show-under-1265,
  .show-under-1024,
  .show-under-840,
  .show-under-760,
  .show-over-650,
  .show-over-480 {
    display: block; }
    .show-under-1265.inline,
    .show-under-1024.inline,
    .show-under-840.inline,
    .show-under-760.inline,
    .show-over-650.inline,
    .show-over-480.inline {
      display: inline-block; }
  .show-over-1265,
  .show-over-1024,
  .show-over-840,
  .show-over-760,
  .show-under-650,
  .show-under-480 {
    display: none; } }

@media screen and (min-width: 480px) and (max-width: 649px) {
  .show-under-1265,
  .show-under-1024,
  .show-under-840,
  .show-under-760,
  .show-under-650,
  .show-over-480 {
    display: block; }
    .show-under-1265.inline,
    .show-under-1024.inline,
    .show-under-840.inline,
    .show-under-760.inline,
    .show-under-650.inline,
    .show-over-480.inline {
      display: inline-block; }
  .show-over-1265,
  .show-over-1024,
  .show-over-840,
  .show-over-760,
  .show-over-650,
  .show-under-480 {
    display: none; } }

@media screen and (max-width: 479px) {
  .show-under-1265,
  .show-under-1024,
  .show-under-840,
  .show-under-760,
  .show-under-650,
  .show-under-480 {
    display: block; }
    .show-under-1265.inline,
    .show-under-1024.inline,
    .show-under-840.inline,
    .show-under-760.inline,
    .show-under-650.inline,
    .show-under-480.inline {
      display: inline-block; }
  .show-over-1265,
  .show-over-1024,
  .show-over-840,
  .show-over-760,
  .show-over-650,
  .show-over-480 {
    display: none; } }
